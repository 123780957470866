import { FC, ReactNode, SyntheticEvent, useContext } from 'react';
import { useTranslations } from 'next-intl';
import cn from 'classnames';
import Container from '@/components/global/Container';
import { HX } from '@/components/global/HX';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Icon from '@/components/global/Icon';
import { UIContext } from '@/context/providers/UIProvider';
import { PopupHomeVideo } from '@/components/global/PopupHomeVideo';
import { pushDataLayer } from '@/helpers';
import css from './style.module.scss';

type HowRepositoryWorkProps = {};

export const HowRepositoryWorks: FC<HowRepositoryWorkProps> = ({}) => {
  const t = useTranslations('homePage');
  const { openPopup } = useContext<{
    openPopup: (component: ReactNode, props: object) => void;
  }>(UIContext as any);

  const openVideo = (e: SyntheticEvent, fromBtn?: boolean) => {
    pushDataLayer({
      event: fromBtn ? 'home_video2_click_btn' : 'home_video2_click_pic',
      element: e.currentTarget,
      target: e.target,
    });
    openPopup(<PopupHomeVideo />, { className: css.popup });
  };

  const list = new Array(7).fill(0).map((_, i) => ({
    title: t(`list.${i}.title`),
    text: t(`list.${i}.text`),
  }));

  return (
    <div className={cn(css.hiw)}>
      <Container className={css.hiwContainer}>
        <HX level="h2" className={css.hiwTitle}>
          {t('howDepoWorks')}
        </HX>
        <div className={css.hiwContentWrapper}>
          <div className={css.listWrapper}>
            <div className={css.hiwImageWrapper}>
              <img
                src="/images/home/video.png"
                alt="yan in the chair"
                className={css.hiwImage}
                onClick={openVideo}
              />
              <Button
                className={cn(css.watchBtn, 'hover-behaviour')}
                onClick={(e) => openVideo(e, true)}
              >
                {t('watchVideo')}{' '}
                <Icon name="playCircle" className={css.icon} />
              </Button>
            </div>
            <ol className={css.list}>
              {list.map((item, i) => (
                <li className={css.listItem} key={item.title}>
                  <div className={css.numWrapper}>
                    <div className={css.numText}>{i + 1}</div>
                  </div>
                  <div>
                    <Text className={css.listTitle}>{item.title}</Text>
                    <Text className={css.listText}>{item.text}</Text>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </Container>
    </div>
  );
};
