const videos: Record<string, string> = {
  en: 'ORL5tHw7HZo',
  ru: 'Vnn9h3EIrhk',
  es: 'n9vcZm12LBQ',
};
export const getVideoIdByLanguage = (lang: string) => {
  const videoId = videos[lang];

  return videoId ? videoId : videos['en'];
};

// English version
// https://youtu.be/ORL5tHw7HZo
// Русская версия
// https://youtu.be/Vnn9h3EIrhk
// Versión en español
// https://youtu.be/n9vcZm12LBQ
