const videos: Record<string, string> = {
  en: 'hicQBSNOYqc',
  ru: 'G0yu397Nup8',
  es: 'UGIkXgMjA1c',
};

export const getVideoIdByLanguage = (lang: string) => {
  const videoId = videos[lang];

  return videoId ? videoId : videos['en'];
};

// English version
// https://youtu.be/hicQBSNOYqc
// Русская версия
// https://youtu.be/G0yu397Nup8
// Versión en español
// https://youtu.be/UGIkXgMjA1c
