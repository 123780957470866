import { FC, SyntheticEvent, useEffect, useRef } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'videojs-youtube';
import 'video.js/dist/video-js.css';
import css from './style.module.scss';

type VideoJSProps = {
  options?: any;
  onReady?: (player: Player) => void;
};

export const VideoJS: FC<VideoJSProps> = ({ options, onReady }) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player>(null);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        player.on('mouseup', (e: SyntheticEvent) => {
          if (!player.isReady_ || player.seeking()) return;
          if ((e.target as HTMLElement).closest('.vjs-control-bar')) {
            return;
          }
          if (player.paused() || player.ended()) {
            player.play();
            return;
          }
          player.pause();
        });
        player.on('touchstart', () => {
          if (!player.isReady_) return;
        });
        player.on('touchend', (e: SyntheticEvent) => {
          if (!player.isReady_) return;
          if ((e.target as HTMLElement).closest('.vjs-control-bar')) {
            return;
          }
          e.preventDefault();
          if (player.seeking()) return;
          if (player.paused()) {
            player.play();
            return;
          }
          player.pause();
        });
      }));
      onReady?.(player);

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      if (!playerRef.current) return;
      const player = playerRef.current;

      player.src(options.sources);
      player.autoplay(options.autoplay);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player className={css.videojsWrapper}>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
