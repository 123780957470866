'use client';

import React, {
  FC,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'next/navigation';

import { getDefaultVideoLanguage, pushDataLayer } from '@/helpers';
import { getVideoIdByLanguage } from './helpers';
import { SelectLanguage } from '../SelectLanguage';

import css from './videoblock.module.scss';
import VideoJS from '@/modules/legend/components/VideoJs';
import Player from 'video.js/dist/types/player';

type VideoBlockProps = {
  ref: RefObject<HTMLDivElement | null>;
};

export const VideoBlock: FC<VideoBlockProps> = ({ ref }) => {
  const { lang } = useParams<{ lang: string }>();
  const [language, setLanguage] = useState(getDefaultVideoLanguage(lang));
  const player = useRef<Player>(null);

  const handleChange = (value: string) => {
    setLanguage(value);
  };

  const video = useMemo(() => {
    return {
      src: `https://youtu.be/${getVideoIdByLanguage(language)}`,
      type: 'video/youtube',
      youtube: { iv_load_policy: 3 },
    };
  }, [language]);

  useEffect(() => {
    if (player.current) {
      player.current.poster(`/images/home/${language}_preview.png`);
    }
  }, [language, player]);

  return (
    <div className={css.container} ref={ref}>
      <SelectLanguage value={language} onChange={handleChange} />
      <div
        className={css.videowrap}
        onClick={(e) => {
          pushDataLayer({
            event: 'home_video_click',
            element: e.currentTarget,
            target: e.target,
          });
        }}
      >
        <VideoJS
          options={{
            autoplay: false,
            controls: true,
            muted: false,
            techOrder: ['html5', 'youtube'],
            preload: 'auto',
            sources: [video],
            fluid: true,
            enableSmoothSeeking: true,
            youtube: { ytControls: 0, enablePrivacyEnhancedMode: 'true' },
          }}
          onReady={(p) => {
            player.current = p;
          }}
        />
      </div>
    </div>
  );
};
