import { useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import VideoJS from '@/modules/legend/components/VideoJs';
import Player from 'video.js/dist/types/player';
import { getVideoIdByLanguage } from './helpers';
import { getDefaultVideoLanguage } from '@/helpers';
import { SelectLanguage } from '@/modules/home/components/SelectLanguage';
import { UIContext } from '@/context/providers/UIProvider';
import css from './styles.module.scss';
import Icon from '../Icon';

export const PopupHomeVideo = () => {
  const { lang } = useParams<{ lang: string }>();
  const [language, setLanguage] = useState(getDefaultVideoLanguage(lang));
  const player = useRef<Player>(null);
  const { closePopup } = useContext<{ closePopup: () => void }>(
    UIContext as any
  );

  const handleChange = (value: string) => {
    setLanguage(value);
  };

  const video = useMemo(() => {
    return {
      src: `https://youtu.be/${getVideoIdByLanguage(language)}`,
      type: 'video/youtube',
      youtube: { iv_load_policy: 3 },
    };
  }, [language]);

  return (
    <div className={css.wrapper}>
      <SelectLanguage
        value={language}
        onChange={handleChange}
        className={css.languageSelector}
      />
      <Icon name="close_smooth" className={css.close} onClick={closePopup} />
      <div className={css.videowrap}>
        <VideoJS
          options={{
            autoplay: true,
            controls: true,
            muted: false,
            techOrder: ['html5', 'youtube'],
            preload: 'auto',
            sources: [video],
            fluid: true,
            enableSmoothSeeking: true,
            youtube: { ytControls: 0, enablePrivacyEnhancedMode: 'true' },
          }}
          onReady={(p) => {
            player.current = p;
          }}
        />
      </div>
    </div>
  );
};
