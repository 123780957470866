import React, { FC, useState } from 'react';
import { Select } from 'antd';
import cn from 'classnames';

import css from './selectlanguage.module.scss';
import Icon from '@/components/global/Icon';
import Text from '@/components/global/Text';

type SelectLanguageProps = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
};

export const SelectLanguage: FC<SelectLanguageProps> = ({
  value,
  onChange,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    { value: 'en', label: <div className={css.option}>English</div> },
    { value: 'ru', label: <div className={css.option}>Russian</div> },
    { value: 'es', label: <div className={css.option}>Spanish</div> },
  ];

  return (
    <div className={cn(css.container, className)}>
      <Text className={css.text}>Video Language:</Text>
      <Select
        className={css.select}
        defaultValue={value}
        dropdownStyle={{
          background: 'var(--gray200)',
          border: 0,
          borderRadius: 0,
          padding: 0,
        }}
        style={{
          padding: '0 8px 0  0',
          background: 'transparent',
        }}
        suffixIcon={
          <Icon
            className={cn(css.icon, css.arrow, { [css.isOpen]: isOpen })}
            name="arrow-down"
          />
        }
        onDropdownVisibleChange={(open) => setIsOpen(open)}
        prefix={<Icon className={css.icon} name="globe1" />}
        onChange={onChange}
        options={options}
        menuItemSelectedIcon={
          <Icon className={cn(css.icon, css.check)} name="check" />
        }
        popupClassName={css.dropdown}
      />
    </div>
  );
};
