import { FC, JSX, PropsWithChildren } from 'react';
import cn from 'classnames';
import css from './style.module.scss';
import Text from '../Text';

type HXProps = PropsWithChildren & {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  onClick?: () => void;
};

export const HX: FC<HXProps> = ({
  className,
  level = 'h1',
  children,
  onClick,
}) => {
  const Tag = level as keyof JSX.IntrinsicElements;
  return (
    <Tag onClick={onClick} className={cn(css.title, className)}>
      {children}
    </Tag>
  );
};
